import './App.css';

function App() {
  return (
    <div className='app'>
      <p className='app-text'>This website is currently a work in progress</p>
      <p>Thank you for visiting</p>
    </div>
  );
}

export default App;
